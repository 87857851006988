@font-face {
    font-family: 'cn-Ef-Font-ExtraBold';
    src: url('https://websiteoss.ecoflow.com/fonts/SourceHanSansCN2/7048c1e9a38f068ddb7581b28e9dff41.woff2'); // 中文站很少使用加粗体，直接引用粗体
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'cn-Ef-Font-Bold';
    src: url('https://websiteoss.ecoflow.com/fonts/SourceHanSansCN2/7048c1e9a38f068ddb7581b28e9dff41.woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'cn-Ef-Font-SemiBold';
    src: url('https://websiteoss.ecoflow.com/fonts/SourceHanSansCN2/6785fd08f15583731f5b094417a6cb4c.woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'cn-Ef-Font-Medium';
    src: url('https://websiteoss.ecoflow.com/fonts/SourceHanSansCN2/5b3928936c8181e045702270574877f7.woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'cn-Ef-Font-Regular';
    src: url('https://websiteoss.ecoflow.com/fonts/SourceHanSansCN2/0a63bc114453095490ad5578d0056bba.woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'cn-Ef-Font-ExtraLight';
    src: url('https://websiteoss.ecoflow.com/fonts/SourceHanSansCN2/243737dfec87e12d880e2b26c7cd0620.woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'cn-Ef-Font-Light';
    src: url('https://websiteoss.ecoflow.com/fonts/SourceHanSansCN2/bf2bcb32189ee6e24aeadc73c45b4b9e.woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
