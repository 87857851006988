
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.lowcodelink {
    :global {
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }
}
