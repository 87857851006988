
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import 'lowcode/common/css/devices.scss';


.content {
    :global {
        outline: none;
        @import 'lowcode/common/css/modules/sales-messages.scss';
        @import 'lowcode/common/css/modules/emarsys-input-mod.scss';
        .glo-editor-modal-wrap {
            .glo-editor-modal-content {
                position: relative;
                background-color: transparent;
            }
            .glo-editor-modal-body {
                padding: 40px;
            }
        }
        .glo-editor-modal-body {
            background-color: #fff;
            border-radius: 0 0 4px 4px;
        }
        .glo-editor-modal-close {
            position: absolute;
            top: 12px;
            right: 12px;
            color: #fff;
        }
        .sales-bg {
            width: 100%;
            height: 228px;
            .lowcode-img {
                border-radius: 16px 16px 0 0;
                overflow: hidden;
            }
        }
        .sales-messages {
            text-align: center;
            .sales-title {
                color: #000;
            }
            .sales-desc {
                color: #000;
            }
        }
        
        .emarsys-input-box {
            display: flex;
            max-width: 500px;
            width: 100%;
            margin: 20px auto 0;
        }
        .emarsys-input-r {
            height: 60px;
            line-height: 60px;
            padding: 0 24px;
            text-align: center;
            white-space: nowrap;
            border-radius: 0px 4px 4px 0px;
            background: linear-gradient(270deg, #FFFCC3 8.31%, #FFBD3C 102.37%);
            cursor: pointer;
        }
        .emarsys-input-l {
            flex: 1;
        }
        .emarsys-input {
            width: 100%;
            height: 60px;
            padding: 0 24px;
            outline: none;
            border-radius: 4px 0 0 4px;
            border: 2px solid #ffbd3c;
            background-color: hsla(0,0%,100%,.2);
            box-sizing: border-box;
        }
        @include devices(desktop) {
            .glo-editor-modal-wrap {
                .glo-editor-modal-content {
                    width: 545px;
                }
                .glo-editor-modal-body {
                    padding: 36px;
                }
            }
            .sales-bg {
                height: 211px;
            }
        }
        @include devices(pad) {
            .glo-editor-modal-wrap {
                .glo-editor-modal-content {
                    width: 414px;
                }
                .glo-editor-modal-body {
                    padding: 24px;
                }
            }
            .sales-bg {
                height: 150px;
                .lowcode-img {
                    border-radius: 10px 10px 0 0;
                }
            }
        }
        @include devices(h5) {
            .glo-editor-modal-wrap {
                .glo-editor-modal-content {
                    width: 300px;
                }
                .glo-editor-modal-body {
                    padding: 24px;
                }
            }
        }
    }
    &:global(.glo-editor-modal) {
        :global {
            z-index: 2222;
        }
    }
}

.resisterWin {
    :global {
        outline: none;
        @import 'lowcode/common/css/modules/sales-messages.scss';
        .glo-editor-modal-content {
            position: relative;
            width: 600px;
            padding: 45px 65px 32px;
            border-radius: 4px;
            .glo-editor-modal-close {
                position: absolute;
                top: 24px;
                right: 24px;
                color: #000;
            }
        }
        .sales-messages  {
            position: relative;
            z-index: 2;
            text-align: center;
            .sales-title {
                color: #000;
            }
            .sales-desc {
                color: #000;
            }
            .win-color2 {
                font-family: var(--ff-woff2-B);
                color: #3f68e0;
            }
        }
        .reg-win-desc2 {
            position: relative;
            width: 352px;
            margin: 100px auto 0;
            font-family: var(--ff-woff2-B);
            font-size: 24px;
            line-height: 1.25;
            color: #fff;
        }
        .reg-win-btn {
            position: relative;
            display: block;
            width: max-content;
            margin: 26px auto 0;
            font-family: var(--ff-woff2-B);
            font-size: 16px;
            line-height: normal;
            padding: 8.5px 12px;
            border-radius: 4px;
            background-color: #fff;
            color: #3f68e0;
            cursor: pointer;
        }
        .reg-win-tips {
            position: relative;
            width: 352px;
            margin: 67px auto 0;
            font-family: var(--ff-woff2-M);
            font-size: 12px;
            line-height: 16px;
            color: #000;
        }
        .sales-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow: hidden;
        }
    }
}