// 2023-09-04 新字体
@font-face{
    font-family: 'MEAR-NOTOSANS-woff2-ExtraBold';
    src: url('https://websiteoss.ecoflow.com/fonts/WOFF2_NotoSans_RU_MEAR/3d9624091297648a561982f553daa778.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'MEAR-NOTOSANS-woff2-Bold';
    src: url('https://websiteoss.ecoflow.com/fonts/WOFF2_NotoSans_RU_MEAR/68414cd6ad510cee899f3cd98b268c7b.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'MEAR-NOTOSANS-woff2-SemiBold';
    src: url('https://websiteoss.ecoflow.com/fonts/WOFF2_NotoSans_RU_MEAR/4d0d0633bbae5719345a1ecaab7eb6e5.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'MEAR-NOTOSANS-woff2-Medium';
    src: url('https://websiteoss.ecoflow.com/fonts/WOFF2_NotoSans_RU_MEAR/4cb996a648d99f859f9fea958853799c.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'MEAR-NOTOSANS-woff2-Regular';
    src: url('https://websiteoss.ecoflow.com/fonts/WOFF2_NotoSans_RU_MEAR/c8f1ab2e0d32b443fe8b26c766b6dbcd.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'MEAR-NOTOSANS-woff2-Light';
    src: url('https://websiteoss.ecoflow.com/fonts/WOFF2_NotoSans_RU_MEAR/f72b2ef961af409667380160ad835f0e.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}