// * .TTF或.OTF，适用于Firefox 3.5、Safari、Opera
// * .EOT，适用于Internet Explorer 4.0+
// * .SVG，适用于Chrome、IPhone

@font-face {
    font-family: 'en-Manrope-Regular';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/cf5e7fe79807bc30dab376b17cf30df2.woff2') format('woff2'), url('/fonts/Manrope/0e2fabf48e1f2d6ad63b3d5932d0bc38.woff') format('woff'),
        url('/fonts/Manrope/2a9713e8ede578466dab54324f19fe27.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/0b726174d2b7e161b9e5e8125bf7751a.ttf') format('truetype'),
        url('/fonts/Manrope/0b104a9a2692c6b0d54a896caa687daf.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-Bold';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/b977e4c21f8ece1ef9533b05b74be644.woff2') format('woff2'), url('/fonts/Manrope/9f11d474a56b565e510eaf19301307be.woff') format('woff'),
        url('/fonts/Manrope/48dfe872f49c92c1c917e9c3925f895d.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/656753569aef606dd528cc6bdf672cdc.ttf') format('truetype'),
        url('/fonts/Manrope/7af5318487d20ed965bc61e9f2f0c3d1.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-ExtraBold';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/20c2727586938b4f0dd261efb5502904.woff2') format('woff2'), url('/fonts/Manrope/8828558e2d5496a00c80cefa07ccda0a.woff') format('woff'),
        url('/fonts/Manrope/a6121846e851632268d228b4f31e1afa.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/47e356f61dca7aa2dfba5593e000c4f1.ttf') format('truetype'),
        url('/fonts/Manrope/427f75da98b3a726a9b2e985e7171866.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-ExtraLight';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/0cd27f321a3aaf8beb4c531af39eb4bc.woff2') format('woff2'), url('/fonts/Manrope/7c9ee7b8a3388e1774c74e540f3bae77.woff') format('woff'),
        url('/fonts/Manrope/83ab4cf312abba07f7b1d3527ecb4fff.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/a4b068ee8a8bdb4d976648992bb1db90.ttf') format('truetype'),
        url('/fonts/Manrope/cf5706c4d17d13225b7d4f924892037a.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-Light';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/6164d6eda310220df4420822271ada67.woff2') format('woff2'), url('/fonts/Manrope/d78c89eee19f8124ba75df62c3c1bc7b.woff') format('woff'),
        url('/fonts/Manrope/f25a6b42ee3327c933937d1716ffe480.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/55aaaa1366df7c6544c2204b032a6e31.ttf') format('truetype'),
        url('/fonts/Manrope/f21a8a7ae48e58acafac88569dc4d964.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-Medium';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/87a7424ded3cbcea0e8017dfdd157fcd.woff2') format('woff2'), url('/fonts/Manrope/47d6d93bcf412e7640f0158c50597957.woff') format('woff'),
        url('/fonts/Manrope/50dad734b3ead0540d44938b46ca6eee.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/6196e0dab83345b15290ee22620358c1.ttf') format('truetype'),
        url('/fonts/Manrope/64e9095fef6f1eac27d5ddcdccbdf720.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Manrope-SemiBold';
    src: url('https://websiteoss.ecoflow.com/fonts/Manrope/5571747b019eda63e734d8c33adf93c5.woff2') format('woff2'), url('/fonts/Manrope/5a0e4f175458cc261bd76fb8fc5e27f4.woff') format('woff'),
        url('/fonts/Manrope/ff32e289dd2b1f886d3859fd8c0d03ad.eot?#iefix') format('embedded-opentype'), url('/fonts/Manrope/255d425d09667bc095e79a8bd8081aba.ttf') format('truetype'),
        url('/fonts/Manrope/0ca19a20281b991d46c56490f05563e4.svg#webfont34M5alKg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Dekko-Regular';
    src: url('https://websiteoss.ecoflow.com/fonts/Dekko/11328261787a1c312fa6ff78af67a700.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'en-Bebas-Regular';
    src: url('https://websiteoss.ecoflow.com/fonts/LandingPage/6f6f82d9243d8111301f39648fb1d62b.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// 新字体 en/de/fr/it/es 2023-09-04
@font-face{
    font-family: 'EN-HelveticNeue-woff2-EB';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/605c36402d16df6ce198ebf0d1aa172d.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-B';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/9e1a68a37975c8f2499df3c809dedc85.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-EM';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/ac056a010bf44acdbcc0922addfb5010.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-M';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/8d454c0abbe5546de71999d0397bfae0.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-EL';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/4455ec462f289a3011dda0ad316f8029.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-HelveticNeue-woff2-L';
    src: url('https://websiteoss.ecoflow.com/fonts/HelveticalNeue20231129/f609858ca6bb3c8ca0dfa340325b9562.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


// 2024/12/11 Ossis页面 @bobo说是钟工和家源的需求 需要用到新字体inter
@font-face{
    font-family: 'EN-Inter-B';
    src: url('/fonts/Inter/Inter_24pt-Bold.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-Inter-S';
    src: url('/fonts/Inter/Inter_24pt-SemiBold.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face{
    font-family: 'EN-Inter-M';
    src: url('/fonts/Inter/Inter_24pt-Medium.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
