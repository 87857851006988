
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        background-color: transparent;
        position: relative;
        width: 140px;
        height: 140px;
        .glo-float-link {
            display: block;
            height: 100%;
            cursor: pointer;
        }
        .glo-float-close {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            font-size: 14px;
            line-height: 14px;
            transform: translate(50%, -50%);
        }
        @include devices(pad) {
            width: 100px;
            height: 100px;
            .glo-float-close {
                font-size: 12px;
            }
        }
    }
    &:global(.glo-float-type-cssType2) {
        :global {
            width: 200px;
            height: 50px;
            @include devices(pad) {
                width: 160px;
                height: 48px;
            }
        }
    }
    &:global(.editor-temp) {
        :global {
            display: block;
        }
    }
    &:global(.glo-lr-hide) {
        :global {
            display: none !important;
        }
    }
}
