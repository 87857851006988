
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@elem/styles/devices.scss';

// 模板需要隐藏
.temp-hide {
    display: none;
}
.editor-temp {
    display: none;
}

:root {
    --color-hover {
        color: #2673ff;
    }
}
body {
    min-width: 375px;
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}
  
[type=button], [type=reset], [type=submit], button {
    background-color: transparent;
    background-image: none;
}

.ef-content {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    @include devices(desktop) {
        padding: 0 64px;
    }
    @include devices(pad) {
        padding: 0 48px;
    }
    @include devices(h5) {
        padding: 0 24px;
    }
}

.ef-container {
    width: 100%;
    max-width: var(--ef-width-pc);
    padding: var(--ef-padding-pc);
    margin-left: auto;
    margin-right: auto;
    @include devices(desktop) {
        max-width: var(--ef-width-desktop);
    }
    @include devices(pad) {
        max-width: 100%;
        padding: var(--ef-padding-pad);
    }
    @include devices(h5) {
        padding: var(--ef-padding-h5);
    }
}

.ef-layer {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    @include devices(desktop) {
        padding: 0 64px;
    }
    @include devices(pad) {
        padding: 0 48px;
    }
    @include devices(h5) {
        padding: 0 24px;
    }
}