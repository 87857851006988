
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.comp {
    :global {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
        transform: translateY(-40px);
        pointer-events: none;
        .message-in {
            display: flex;
            justify-content: center;
        }
        .message-title {
            display: flex;
            background-color: #fff;
            border-radius: 4px;
            line-height: 20px;
            padding: 10px 20px;
            
        }
    }
    &:global(.message-enter) {
        :global {
            transition: transform 0.5s;
            transform: translateY(40px);
        }
    }
    &:global(.message-close) {
        :global {
            opacity: 0;
        }
    }
}
